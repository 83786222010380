// Vendor Imports
import _ from 'lodash';
import { createSelector } from 'reselect';

import getDefaultDomain from 'common/visualizations/helpers/getDefaultDomain';
import {
  SERIES_TYPE_AG_GRID_TABLE,
  SERIES_TYPE_DATA_TABLE,
  SERIES_TYPE_TABLE
} from 'common/visualizations/views/SvgConstants';

// Constants
import {
  BASEMAP_STYLES,
  CALENDAR,
  COLOR_PALETTE_VALUES,
  DEFAULT_COLOR_PALETTE,
  DEFAULT_SCATTER_CHART_COLUMN_VALUE,
  DEFAULT_SHAPE_FILL_COLOR,
  DEFAULT_SHAPE_FILL_OPACITY,
  DEFAULT_SHAPE_OUTLINE_COLOR,
  DEFAULT_SHAPE_OUTLINE_WIDTH,
  ERROR_BARS_DEFAULT_BAR_COLOR,
  NULL_CHARM_NAME,
  QUANTIFICATION_METHODS,
  RANGE_BUCKET_TYPES,
  SCATTER_CHART,
  SERIES_TYPE_BAR_CHART,
  SERIES_TYPE_COLUMN_CHART,
  SERIES_TYPE_COMBO_CHART_LINE,
  SERIES_TYPE_PIE_CHART,
  SERIES_VARIANT_LINE,
  VECTOR_BASEMAP_STYLES,
  VIF_CONSTANTS
} from '../constants';

import * as vifSelectors from 'common/visualizations/helpers/VifSelectors';
import { getMapColorGroupNameByColumn } from '../../visualizations/views/map/vifDecorators/commonVifDecorator';

export const getAuthoring = state => _.get(state, 'authoring', {});
export const getCheckpointVifs = state => _.get(state, 'authoring.checkpointVifs', {});
export const getCustomColorPaletteError = state => _.get(state, 'authoring.customColorPaletteError', null);
export const getFilters = state => _.get(state, 'filters', []);
export const getSelectedVisualizationType = state => _.get(state, 'authoring.selectedVisualizationType', null);
export const getShowCenteringAndZoomingSaveMessage = state => _.get(state, 'authoring.showCenteringAndZoomingSaveMessage', null);
export const isUserCurrentlyActive = state => _.get(state, 'authoring.userCurrentlyActive', false);
export const getVifs = state => _.get(state, 'vifs', {});

export const getCurrentVif = createSelector(
  getVifs,
  getSelectedVisualizationType,
  (vifs, selectedVisualizationType) => _.get(vifs, selectedVisualizationType, {})
);

export const getEmptyVif = createSelector(
  getCurrentVif,
  (vif) => {
    const emptyVif = {
      format: {
        type: 'visualization_interchange_format',
        version: VIF_CONSTANTS.LATEST_VERSION
      }
    };

    const domain = _.get(vif, 'series[0].dataSource.domain');
    const datasetUid = _.get(vif, 'series[0].dataSource.datasetUid');

    if (!_.isNil(domain)) {
      _.set(emptyVif, 'series[0].dataSource.domain', domain);
    }

    if (!_.isNil(datasetUid)) {
      _.set(emptyVif, 'series[0].dataSource.datasetUid', datasetUid);
    }

    return emptyVif;
  }
);

export const getCurrentSeriesIndex = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'currentMapLayerIndex', VIF_CONSTANTS.CURRENT_MAP_LAYER_INDEX)
);

export const getConfiguration = createSelector(
  getCurrentVif,
  (vif) => vif.configuration
);

export const getDimension = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'dataSource', 'dimension'], VIF_CONSTANTS.DEFAULT_DIMENSION)
);

export const getCurrentDrilldownColumnName = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'dataSource', 'dimension', 'currentDrilldownColumnName'])
);

export const getChartFilters = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'dataSource', 'filters'], [])
);

export const getDrilldowns = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'dataSource', 'dimension', 'drilldowns'], [])
);

export const getTableColumns = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'dataSource', 'dimension', 'columns'], [])
);

export const getTableHierarchies = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'dataSource', 'hierarchies'], [])
);

export const getHeaderFormat = createSelector(getCurrentVif, getCurrentSeriesIndex, (vif, seriesIndex) =>
  _.get(vif, ['series', seriesIndex, 'formatting', 'headerFormat'], {})
);

// Called in common/authoring_workflow/components/panes/PresentationPane/AgGridUpdated/AgGridColumnFormatting/index.tsx#L26
export const getColumnFormats = createSelector(getCurrentVif, getCurrentSeriesIndex, (vif, seriesIndex) =>
  _.get(vif, ['series', seriesIndex, 'formatting', 'columnFormat'], {})
);

export const getDimensionGrouping = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'dataSource', 'dimension', 'grouping'], VIF_CONSTANTS.DEFAULT_DIMENSION_GROUPING)
);

// Warning: This should only be used if you know what you're doing.
// In other words, this is going to randomly choose a VIF and grab its
// dimension.
export const getAnyDimension = createSelector(
  getCurrentVif,
  getVifs,
  getSelectedVisualizationType,
  getCurrentSeriesIndex,
  (vif, vifs, selectedVisualizationType, seriesIndex) => {
    if (_.isEmpty(vif)) {
      if (selectedVisualizationType === 'map') {
        return _.get(vifs, `map.series[${seriesIndex}].dataSource.dimension`, VIF_CONSTANTS.DEFAULT_DIMENSION);
      } else {
        return _.get(vifs, 'columnChart.series[0].dataSource.dimension', VIF_CONSTANTS.DEFAULT_DIMENSION);
      }
    }

    return _.get(vif, ['series', seriesIndex, 'dataSource', 'dimension'], VIF_CONSTANTS.DEFAULT_DIMENSION);
  }
);

export const getCurrentDimensionColumnName = createSelector(
  getAnyDimension,
  getDrilldowns,
  getCurrentDrilldownColumnName,
  (dimension, drilldowns, currentDrilldownColumnName) => {

    return !_.isEmpty(drilldowns) ?
      { columnName: currentDrilldownColumnName } :
      dimension;
  }
);

export const getMeasure = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'dataSource', 'measure'], VIF_CONSTANTS.DEFAULT_MEASURE)
);

export const getReferenceLines = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'referenceLines', [])
);

export const hasReferenceLineLabels = createSelector(
  getReferenceLines,
  (lines) => _.filter(lines, (line) => !_.isEmpty(line.label)).length > 0
);

export const getSeriesLength = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'series', []).length
);

export const getSeries = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'series', [])
);

export const getSeriesZero = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'series[0]', [])
);

export const getNonFlyoutSeries = createSelector(
  getCurrentVif,
  vifSelectors.getNonFlyoutSeries
);

export const hasSeriesVariantLine = createSelector(
  getSeries,
  (series) => !_.isNil(_.find(series, (item) => item.type.includes(SERIES_VARIANT_LINE)))
);

export const getPrimaryMapLayerSeries = createSelector(
  getSeries,
  getSeriesZero,
  (series, seriesZero) => {
    const primarySeries = _.filter(series, (item) => item.primary);

    return _.isEmpty(primarySeries) ? seriesZero : primarySeries[0];
  }
);

export const getPrimaryMapLayerSeriesIndex = createSelector(
  getSeries,
  (series) => _.findIndex(series, (item) => item.primary)
);

export const getPointMapSeries = createSelector(
  getSeries,
  (series) => _.filter(series, (item) => _.get(item, 'mapOptions.mapType') === 'pointMap')
);

export const getUseSecondaryAxisForColumns = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.useSecondaryAxisForColumns', VIF_CONSTANTS.DEFAULT_SECONDARY_AXIS_FOR_COLUMNS)
);

export const getUseSecondaryAxisForLines = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.useSecondaryAxisForLines', VIF_CONSTANTS.DEFAULT_SECONDARY_AXIS_FOR_LINES)
);

export const isUsingPrimaryAxis = createSelector(
  getCurrentVif,
  (vif) =>
    !_.get(vif, 'configuration.useSecondaryAxisForColumns', VIF_CONSTANTS.DEFAULT_SECONDARY_AXIS_FOR_COLUMNS) ||
    !_.get(vif, 'configuration.useSecondaryAxisForLines', VIF_CONSTANTS.DEFAULT_SECONDARY_AXIS_FOR_LINES)
);

export const isUsingPrimaryAxisOnly = createSelector(
  getCurrentVif,
  (vif) =>
    !_.get(vif, 'configuration.useSecondaryAxisForColumns', VIF_CONSTANTS.DEFAULT_SECONDARY_AXIS_FOR_COLUMNS) &&
    !_.get(vif, 'configuration.useSecondaryAxisForLines', VIF_CONSTANTS.DEFAULT_SECONDARY_AXIS_FOR_LINES)
);

export const isUsingSecondaryAxis = createSelector(
  getCurrentVif,
  (vif) =>
    _.get(vif, 'configuration.useSecondaryAxisForColumns', VIF_CONSTANTS.DEFAULT_SECONDARY_AXIS_FOR_COLUMNS) ||
    _.get(vif, 'configuration.useSecondaryAxisForLines', VIF_CONSTANTS.DEFAULT_SECONDARY_AXIS_FOR_LINES)
);

export const isUsingSecondaryAxisOnly = createSelector(
  getCurrentVif,
  (vif) =>
    _.get(vif, 'configuration.useSecondaryAxisForColumns', VIF_CONSTANTS.DEFAULT_SECONDARY_AXIS_FOR_COLUMNS) &&
    _.get(vif, 'configuration.useSecondaryAxisForLines', VIF_CONSTANTS.DEFAULT_SECONDARY_AXIS_FOR_LINES)
);

export const getTitle = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'title', VIF_CONSTANTS.DEFAULT_TITLE)
);

export const getCenterAndZoom = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.mapCenterAndZoom')
);

export const getDescription = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'description', VIF_CONSTANTS.DEFAULT_DESCRIPTION)
);

export const getPrimaryColor = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'color', 'primary'], VIF_CONSTANTS.DEFAULT_PRIMARY_COLOR_VALUE)
);

export const getSecondaryColor = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'color', 'secondary'], VIF_CONSTANTS.DEFAULT_SECONDARY_COLOR_VALUE)
);

export const getPointOpacity = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'mapOptions', 'pointOpacity'], VIF_CONSTANTS.POINT_OPACITY.DEFAULT)
);

export const getPointSize = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'mapOptions', 'pointSize'], VIF_CONSTANTS.POINT_MAP_POINT_SIZE.DEFAULT)
);

export const getMapType = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'mapOptions', 'mapType'], VIF_CONSTANTS.DEFAULT_MAP_TYPE_VALUE)
);

export const getTriggerAutoSelectGeoLocationColumn = createSelector(
  getAuthoring,
  (authoring) => _.get(authoring, 'triggerAutoSelectGeoLocationColumn', VIF_CONSTANTS.DEFAULT_GEO_LOCATION_COLUMN)
);

export const getCurrentMapLayerEditView = createSelector(
  getAuthoring,
  (authoring) => _.get(authoring, 'currentMapLayerIndexView', 'options_view')
);

export const getShowDatasetSelectionModal = createSelector(
  getAuthoring,
  (authoring) => _.get(authoring, 'showDatasetSelectionModal', VIF_CONSTANTS.DEFAULT_SHOW_DATASET_SELECTION_VALUE)
);

export const getResizePointsByColumn = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'mapOptions', 'resizePointsBy'], VIF_CONSTANTS.DEFAULT_RESIZE_POINTS_BY_COLUMN_VALUE)
);

export const getColorByColumn = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, ['series', SCATTER_CHART.COLOR_BY_SERIES_INDEX, 'dataSource', 'measure', 'columnName'], VIF_CONSTANTS.DEFAULT_COLOR_BY_COLUMN_VALUE)
);

export const getResizeByColumn = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, ['series', SCATTER_CHART.RESIZE_BY_SERIES_INDEX, 'dataSource', 'measure', 'columnName'], VIF_CONSTANTS.DEFAULT_RESIZE_BY_COLUMN_VALUE)
);

export const getXAxisColumn = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, ['series', SCATTER_CHART.X_AXIS_SERIES_INDEX, 'dataSource', 'measure', 'columnName'], DEFAULT_SCATTER_CHART_COLUMN_VALUE)
);

export const getYAxisColumn = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, ['series', SCATTER_CHART.Y_AXIS_SERIES_INDEX, 'dataSource', 'measure', 'columnName'], DEFAULT_SCATTER_CHART_COLUMN_VALUE)
);

export const getMinimumPlotSize = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'chartOptions', 'minimumPlotSize'], SCATTER_CHART.MIN_PLOT_SIZE.DEFAULT)
);

export const getMaximumPlotSize = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'chartOptions', 'maximumPlotSize'], SCATTER_CHART.MAX_PLOT_SIZE.DEFAULT)
);

export const getMinimumPointSize = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'mapOptions', 'minimumPointSize'], VIF_CONSTANTS.POINT_MAP_MIN_POINT_SIZE.DEFAULT)
);

export const getMaximumPointSize = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'mapOptions', 'maximumPointSize'], VIF_CONSTANTS.POINT_MAP_MAX_POINT_SIZE.DEFAULT)
);

export const getNumberOfDataClasses = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'mapOptions', 'numberOfDataClasses'], VIF_CONSTANTS.NUMBER_OF_DATA_CLASSES.DEFAULT)
);

export const getScatterChartNumberOfDataClasses = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'chartOptions', 'numberOfDataClasses'], VIF_CONSTANTS.NUMBER_OF_DATA_CLASSES.DEFAULT)
);

export const getColorByBucketsCount = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'mapOptions', 'colorByBucketsCount'], VIF_CONSTANTS.COLOR_BY_BUCKETS_COUNT.DEFAULT)
);

export const getMidpoint = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'mapOptions', 'midpoint'], VIF_CONSTANTS.DEFAULT_MID_POINT)
);

export const getMaxClusteringZoomLevel = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.basemapOptions.maxClusteringZoomLevel', VIF_CONSTANTS.CLUSTERING_ZOOM.DEFAULT)
);

export const getPlotSize = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.plotSize', SCATTER_CHART.PLOT_SIZE.DEFAULT)
);

export const getDefaultDisplayDate = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.defaultDisplayDate', VIF_CONSTANTS.DEFAULT_DISPLAY_DATE)
);

export const getEndDateColumn = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, ['series', CALENDAR.END_DATE_SERIES_INDEX, 'dataSource', 'dimension', 'columnName'], VIF_CONSTANTS.DEFAULT_END_DATE_COLUMN_VALUE)
);

export const getEventTitleColumn = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, ['series', CALENDAR.EVENT_TITLE_SERIES_INDEX, 'dataSource', 'dimension', 'columnName'], VIF_CONSTANTS.DEFAULT_EVENT_TITLE_COLUMN_VALUE)
);

export const getStartDateColumn = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, ['series', CALENDAR.START_DATE_SERIES_INDEX, 'dataSource', 'dimension', 'columnName'], VIF_CONSTANTS.DEFAULT_START_DATE_COLUMN_VALUE)
);

export const getEventBackgroundColor = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'color', 'eventBackgroundColor'], VIF_CONSTANTS.DEFAULT_CALENDAR_BACK_GROUND_COLOR)
);

export const getEventOutlineColor = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'color', 'eventOutlineColor'], VIF_CONSTANTS.DEFAULT_CALENDAR_EVENT_OUTLINE_COLOR)
);

export const getEventTextColor = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'color', 'eventTextColor'], VIF_CONSTANTS.DEFAULT_CALENDAR_TEXT_COLOR)
);

export const getMapZoomLevel = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.mapZoomLevel', VIF_CONSTANTS.DEFAULT_MAP_ZOOM_LEVEL)
);

export const getPointThreshold = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.basemapOptions.pointThreshold', VIF_CONSTANTS.POINT_THRESHOLD.DEFAULT)
);

export const getClusterRadius = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.basemapOptions.clusterRadius', VIF_CONSTANTS.CLUSTER_RADIUS.DEFAULT)
);

export const getMaxClusterSize = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.basemapOptions.maxClusterSize', VIF_CONSTANTS.CLUSTER_SIZE.DEFAULT)
);

export const getStackRadius = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.basemapOptions.stackRadius', VIF_CONSTANTS.STACK_RADIUS.DEFAULT)
);

export const getColorPointsByColumn = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'mapOptions', 'colorPointsBy'], VIF_CONSTANTS.DEFAULT_COLOR_POINTS_BY_COLUMN_VALUE)
);

export const getLineWeight = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'mapOptions', 'lineWeight'], VIF_CONSTANTS.LINE_WEIGHT.DEFAULT)
);

export const getWeighLinesByColumn = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'mapOptions', 'weighLinesBy'], VIF_CONSTANTS.DEFAULT_WEIGHT_LINES_BY_COLUMN_VALUE)
);

export const getMinimumLineWeight = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'mapOptions', 'minimumLineWeight'], VIF_CONSTANTS.LINE_WEIGHT.MIN)
);

export const getMaximumLineWeight = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'mapOptions', 'maximumLineWeight'], VIF_CONSTANTS.LINE_WEIGHT.MAX)
);

export const getColorLinesByColumn = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'mapOptions', 'colorLinesBy'], VIF_CONSTANTS.DEFAULT_COLOR_LINES_BY_COLUMN_VALUE)
);

export const getSimplificationLevel = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'mapOptions', 'simplificationLevel'], VIF_CONSTANTS.SIMPLIFICATION_LEVEL.DEFAULT)
);

export const getCharmName = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'mapOptions', 'charmName'], NULL_CHARM_NAME)
);

export const getBoundaryColorByColumn = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'mapOptions', 'colorBoundariesBy'], VIF_CONSTANTS.DEFAULT_BOUNDARY_COLOR_BY_COLUMN_VALUE)
);

export const getShapeFillColor = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'mapOptions', 'shapeFillColor'], DEFAULT_SHAPE_FILL_COLOR)
);

export const getShapeFillOpacity = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => {
    const shapeFillOpacity = parseInt(_.get(vif, ['series', seriesIndex, 'mapOptions', 'shapeFillOpacity']));

    return _.isNaN(shapeFillOpacity) ? DEFAULT_SHAPE_FILL_OPACITY : _.clamp(shapeFillOpacity, VIF_CONSTANTS.SHAPE_FILL_OPACITY.MIN, VIF_CONSTANTS.SHAPE_FILL_OPACITY.MAX);
  }
);

export const getLineColorOpacity = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => {
    const lineColorOpacity = parseInt(_.get(vif, ['series', seriesIndex, 'mapOptions', 'lineColorOpacity']), 10);

    return _.isNaN(lineColorOpacity) ? VIF_CONSTANTS.LINE_COLOR_OPACITY.DEFAULT : _.clamp(lineColorOpacity, VIF_CONSTANTS.LINE_COLOR_OPACITY.MIN, VIF_CONSTANTS.LINE_COLOR_OPACITY.MAX);
  }
);

export const getShapeOutlineColor = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'mapOptions', 'shapeOutlineColor'], DEFAULT_SHAPE_OUTLINE_COLOR)
);

export const getShapeOutlineWidth = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => {
    const shapeOutlineWidth = parseFloat(_.get(vif, ['series', seriesIndex, 'mapOptions', 'shapeOutlineWidth']));

    return _.isNaN(shapeOutlineWidth) ? DEFAULT_SHAPE_OUTLINE_WIDTH : _.clamp(shapeOutlineWidth, VIF_CONSTANTS.SHAPE_OUTLINE_WIDTH.MIN, VIF_CONSTANTS.SHAPE_OUTLINE_WIDTH.MAX);
  }
);

export const getColorByQuantificationMethod = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'mapOptions', 'colorByQuantificationMethod'], QUANTIFICATION_METHODS.category.value)
);

export const getRangeBucketType = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'mapOptions', 'rangeBucketType'], RANGE_BUCKET_TYPES.jenks.value)
);

export const getPointAggregation = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'mapOptions', 'pointAggregation'], VIF_CONSTANTS.DEFAULT_POINT_AGGREGATION)
);

export const getBasemapStyle = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.basemapOptions.basemapStyle', VECTOR_BASEMAP_STYLES.basic.value)
);

export const getBasemapType = createSelector(
  getCurrentVif,
  getBasemapStyle,
  (vif, basemapStyle) => _.find(BASEMAP_STYLES, (style) => style.value === basemapStyle).title
);

export const getBasemapOpacity = createSelector(
  getCurrentVif,
  (vif) => {
    const basemapOpacity = _.round(_.get(vif, 'configuration.basemapOptions.basemapOpacity'), 2);

    return _.isNaN(basemapOpacity) ? VIF_CONSTANTS.BASEMAP_OPACITY.MAX : _.clamp(basemapOpacity, VIF_CONSTANTS.BASEMAP_OPACITY.MIN, VIF_CONSTANTS.BASEMAP_OPACITY.MAX);
  }
);

export const getNavigationControl = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.basemapOptions.navigationControl', VIF_CONSTANTS.DEFAULT_NAVIGATION_CONTROL)
);

export const getLayerToggleControl = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.basemapOptions.layerToggleControl', VIF_CONSTANTS.DEFAULT_LAYER_TOGGLE_CONTROL)
);

export const getCastNullAsFalseInSeries = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'mapOptions', 'castNullAsFalseInSeries'], VIF_CONSTANTS.DEFAULT_CAST_NULL_AS_FALSE)
);

export const getGeoCoderControl = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.basemapOptions.geoCoderControl', VIF_CONSTANTS.DEFAULT_GEO_CODER_CONTROL)
);

export const getShowDataTableControl = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.showDataTableControl', VIF_CONSTANTS.DEFAULT_SHOW_DATA_TABLE_CONTROL)
);

export const getLockCalendarViewControl = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.lockCalendarViewControl', VIF_CONSTANTS.DEFAULT_LOCK_CALENDAR_VIEW_CONTROL)
);

export const getGeoLocateControl = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.basemapOptions.geoLocateControl', VIF_CONSTANTS.DEFAULT_GEO_LOCATION_CONTROL)
);

export const getMapFlyoutTitleColumnName = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'mapOptions', 'mapFlyoutTitleColumnName'], VIF_CONSTANTS.DEFAULT_MAP_FLYOUT_TITLE_VALUE)
);

export const getAdditionalFlyoutColumns = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'mapOptions', 'additionalFlyoutColumns'], [])
);

export const getRegionMapFlyoutColumnAndAggregations = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'mapOptions', 'regionMapFlyoutColumnAndAggregations'], [])
);

export const getSearchBoundaryUpperLeftLatitude = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.basemapOptions.searchBoundaryUpperLeftLatitude', '')
);

export const getSearchBoundaryUpperLeftLongitude = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.basemapOptions.searchBoundaryUpperLeftLongitude', '')
);

export const getSearchBoundaryLowerRightLatitude = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.basemapOptions.searchBoundaryLowerRightLatitude', '')
);

export const getSearchBoundaryLowerRightLongitude = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.basemapOptions.searchBoundaryLowerRightLongitude', '')
);

export const getColorScale = createSelector(
  getCurrentVif,
  (vif) => {
    return {
      negativeColor: _.get(vif, 'configuration.legend.negativeColor'),
      zeroColor: _.get(vif, 'configuration.legend.zeroColor'),
      positiveColor: _.get(vif, 'configuration.legend.positiveColor')
    };
  }
);

export const getColorPalette = (defaultPalette = null) => createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'color', 'palette'], defaultPalette)
);

export const getMapColorPalette = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'color', 'palette'], DEFAULT_COLOR_PALETTE)
);

export const getBaseLayer = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.baseLayerUrl')
);

export const getBaseLayerOpacity = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.baseLayerOpacity', 1) * 100
);

export const getShapefileUid = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'shapefile', 'uid'], VIF_CONSTANTS.DEFALUT_SHAPE_FILE_UID_VALUE)
);

export const getRegionCodingShapefileIdInProcess = createSelector(
  getAuthoring,
  (authoring) => _.get(authoring, 'regionCodingShapefileIdInProcess', VIF_CONSTANTS.DEFAULT_REGION_CODING_SHAPE_FILE_ID_VALUE)
);

export const getUnitOne = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'unit', 'one'], VIF_CONSTANTS.DEFAULT_FLYOUT_UNIT)
);

export const getUnitOther = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'unit', 'other'], VIF_CONSTANTS.DEFAULT_FLYOUT_UNIT)
);

export const getRowInspectorTitleColumnName = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.rowInspectorTitleColumnName', VIF_CONSTANTS.DEFAULT_ROW_INSPECTOR_TITLE_VALUE)
);

export const getDatasetUid = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'dataSource', 'datasetUid'], VIF_CONSTANTS.DEFAULT_DATASET_UID)
);

export const getLegendPosition = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.legendPosition', VIF_CONSTANTS.DEFAULT_LEGEND_POSITION)
);

export const getMapLayerName = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'dataSource', 'name'], VIF_CONSTANTS.DEFAULT_MAP_LAYER_NAME)
);

export const getMapLayerSource = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'dataSource', 'source'], VIF_CONSTANTS.DEFAULT_MAP_LAYER_SOURCE)
);

export const getDomain = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'dataSource', 'domain']) || getDefaultDomain()
);

export const getAxisLabels = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.axisLabels')
);

export const getViewSourceDataLink = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.viewSourceDataLink', VIF_CONSTANTS.DEFAULT_VIEW_SOURCE_DATA_LINK)
);

export const getShowDimensionLabels = createSelector(
  getCurrentVif,
  vifSelectors.getShowDimensionLabels
);

export const getDimensionLabelRotationAngle = createSelector(
  getCurrentVif,
  vifSelectors.getDimensionLabelRotationAngle
);

export const getLineStylePoints = createSelector(
  getCurrentVif,
  vifSelectors.getLineStylePoints
);

export const getAnnotations = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'series[0].annotations', [])
);

export const getShowLineValueLabels = createSelector(
  getCurrentVif,
  vifSelectors.getShowLineValueLabels
);

export const getShowNullsAsFalse = createSelector(
  getCurrentVif,
  vifSelectors.getShowNullsAsFalse
);

export const getShowValueLabels = createSelector(
  getCurrentVif,
  vifSelectors.getShowValueLabels
);

export const getShowValueLabelsAsPercent = createSelector(
  getCurrentVif,
  vifSelectors.getShowValueLabelsAsPercent
);

export const getShowLegend = (defaultValue = VIF_CONSTANTS.DEFAULT_SHOW_LEGEND) => createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'showLegend'], defaultValue)
);

export const getShowLegendForMap = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.showLegendForMap', VIF_CONSTANTS.DEFAULT_SHOW_LEGEND_FOR_MAP)
);

export const getShowAnnotationsInLegend = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.showAnnotationsInLegend', VIF_CONSTANTS.DEFAULT_SHOW_ANNOTATIONS_IN_LEGEND)
);

export const getShowLegendOpened = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.showLegendOpened', VIF_CONSTANTS.DEFAULT_SHOW_LEGEND_OPENED)
);

export const getShowMultiplePointsSymbolInLegend = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.showMultiplePointsSymbolInLegend', VIF_CONSTANTS.DEFAULT_SHOW_MULTIPLE_POINTS_SYMBOL_IN_LEGEND)
);

export const getShowSlicePercentsInFlyouts = createSelector(
  getCurrentVif,
  vifSelectors.getShowSlicePercentsInFlyouts
);

export const getWrapDimensionLabels = createSelector(
  getCurrentVif,
  vifSelectors.getWrapDimensionLabels
);

export const getXAxisScalingMode = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.xAxisScalingMode')
);

export const getMeasureAxisMaxValue = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.measureAxisMaxValue', VIF_CONSTANTS.DEFAULT_MEASURE_AXIS_MAX_VALUE)
);

export const getMeasureAxisMinValue = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.measureAxisMinValue', VIF_CONSTANTS.DEFAULT_MEASURE_AXIS_MIN_VALUE)
);

export const getMeasureAxisPrecision = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.measureAxisPrecision', VIF_CONSTANTS.DEFAULT_MEASURE_AXIS_PRECISION)
);

export const getMapLegendPrecision = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.mapLegendPrecision', VIF_CONSTANTS.DEFAULT_MAP_LEGEND_PRECISION)
);

export const getMapFlyoutPrecision = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.mapFlyoutPrecision', VIF_CONSTANTS.DEFAULT_MAP_FLYOUT_PRECISION)
);

export const getSecondaryMeasureAxisPrecision = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.secondaryMeasureAxisPrecision', VIF_CONSTANTS.DEFAULT_MEASURE_AXIS_PRECISION)
);

export const getMeasureAxisScale = createSelector(
  getCurrentVif,
  vifSelectors.getMeasureAxisScale
);

export const isLogarithmicScale = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.logarithmicScale', VIF_CONSTANTS.DEFAULT_LOGARITHMIC_SCALE)
);

export const getSecondaryMeasureAxisMaxValue = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.secondaryMeasureAxisMaxValue', VIF_CONSTANTS.DEFAULT_SECONDARY_MEASURE_AXIS_MAX_VALUE)
);

export const getSecondaryMeasureAxisMinValue = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.secondaryMeasureAxisMinValue', VIF_CONSTANTS.DEFAULT_SECONDARY_MEASURE_AXIS_MIN_VALUE)
);

export const getSecondaryMeasureAxisScale = createSelector(
  getCurrentVif,
  vifSelectors.getSecondaryMeasureAxisScale
);

export const getComboChartLineSeries = createSelector(
  getSeries,
  (series) => _.filter(series, (item) => item.type === SERIES_TYPE_COMBO_CHART_LINE)
);

export const hasComboChartLineSeries = createSelector(
  getComboChartLineSeries,
  (series) => series.length > 0
);

export const isUsingSecondaryAxisForLines = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.useSecondaryAxisForLines', false)
);

export const getDimensionGroupingColumnName = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'dataSource', 'dimension', 'grouping', 'columnName'], VIF_CONSTANTS.DEFAULT_DIMENSION_GROUPING_COLUMN_VALUE)
);

export const getErrorBarsLowerBoundColumnName = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'series[0].errorBars.lowerBoundColumnName')
);

export const getErrorBarsUpperBoundColumnName = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'series[0].errorBars.upperBoundColumnName')
);

export const getErrorBarsColor = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'series[0].errorBars.barColor', ERROR_BARS_DEFAULT_BAR_COLOR)
);

export const hasErrorBars = createSelector(
  getErrorBarsLowerBoundColumnName,
  getErrorBarsUpperBoundColumnName,
  (lowerBoundColumnName, upperBoundColumnName) => !_.isUndefined(lowerBoundColumnName) && !_.isUndefined(upperBoundColumnName)
);

export const getStacked = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'series[0].stacked', false)
);

export const hasDimensionGroupingColumnName = createSelector(
  getDimensionGroupingColumnName,
  (groupingColumnName) => !_.isEmpty(groupingColumnName)
);

export const getColorPaletteGroupingColumnName = createSelector(
  getCurrentVif,
  getSelectedVisualizationType,
  getPointAggregation,
  getDimensionGroupingColumnName,
  getMapType,
  getCurrentSeriesIndex,
  (vif, visualizationType, pointAggregation, grouping, mapType, seriesIndex) => {
    if (visualizationType === 'pieChart') {
      return _.get(vif, 'series[0].dataSource.dimension.columnName', VIF_CONSTANTS.DEFAULT_DIMENSION);
    }

    if (visualizationType === 'scatterChart') {
      return _.get(vif, `series[${SCATTER_CHART.COLOR_BY_SERIES_INDEX}].dataSource.measure.columnName`);
    }

    if (visualizationType === 'map') {
      return getMapColorGroupNameByColumn(vif, mapType, pointAggregation, seriesIndex);
    }

    return grouping;
  }
);

export const getCustomColorPalettes = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, `series[${seriesIndex}].color.customPalette`, {})
);

export const getCustomColorPaletteForNewGLMaps = createSelector(
  getCurrentVif,
  getColorByBucketsCount,
  getCurrentSeriesIndex,
  (vif, colorByBucketsCount, seriesIndex) => _.get(
    vif,
    ['series', seriesIndex, 'color', 'customPalette'],
    _.take(COLOR_PALETTE_VALUES[DEFAULT_COLOR_PALETTE], colorByBucketsCount + 1)
  )
);

export const hasCustomColorPalette = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'color', 'palette']) === 'custom'
);

export const getDateDisplayFormat = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'series[0].dataSource.dateDisplayFormat')
);

export const getGroupingOrderBy = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'series[0].dataSource.dimension.grouping.orderBy')
);

export const getOrderBy = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'series[0].dataSource.orderBy')
);

export const getPrecision = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'series[0].dataSource.precision')
);

export const getTreatNullValuesAsZero = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'configuration.treatNullValuesAsZero')
);

export const getLimitCount = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'series[0].dataSource.limit', VIF_CONSTANTS.DEFAULT_LIMIT_COUNT)
);

export const getShowOtherCategory = createSelector(
  getCurrentVif,
  (vif) => {
    return (
      !_.isNull(_.get(vif, 'series[0].dataSource.limit')) &&
      _.get(vif, 'configuration.showOtherCategory')
    );
  }
);

export const getVisualizationType = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => {
    const type = _.get(vif, ['series', seriesIndex, 'type'], VIF_CONSTANTS.DEFAULT_VISUALIZATION_TYPE);

    return _.isNull(type) ? VIF_CONSTANTS.DEFAULT_VISUALIZATION_TYPE : type.split('.')[0];
  }
);

export const hasVisualizationType = createSelector(
  getVisualizationType,
  (type) => _.isString(type)
);

export const hasVisualizationDimension = createSelector(
  getDimension,
  (dimension) => {
    const columnName = _.get(dimension, 'columnName', null);
    return !_.isNull(columnName);
  }
);

export const hasDimensionWithDrilldowns = createSelector(
  getVisualizationType,
  getDrilldowns,
  (type, drilldowns) => {
    const visualizationType = type === SERIES_TYPE_BAR_CHART || type === SERIES_TYPE_COLUMN_CHART || type === SERIES_TYPE_PIE_CHART;
    return visualizationType && drilldowns.length > 0;
  }
);

export const isGrouping = createSelector(
  getDimensionGroupingColumnName,
  (dimensionGroupingColumnName) => _.isString(dimensionGroupingColumnName)
);

export const hasMultipleNonFlyoutSeries = createSelector(
  getNonFlyoutSeries,
  (series) => series.length > 1
);

export const isGroupingOrHasMultipleNonFlyoutSeries = createSelector(
  getCurrentVif,
  vifSelectors.isGroupingOrHasMultipleNonFlyoutSeries
);

export const isColorByColumnConfigured = createSelector(
  getColorByColumn,
  (colorByColumn) => !_.isNil(colorByColumn)
);

export const isStacked = createSelector(
  getCurrentVif,
  vifSelectors.isVifStacked
);

export const isOneHundredPercentStacked = createSelector(
  getCurrentVif,
  (vif) => _.get(vif, 'series[0].stacked.oneHundredPercent', false)
);

export const isRegionMap = createSelector(
  getVisualizationType,
  (type) => type === 'regionMap'
);

export const isCurrentMapLayerPrimary = createSelector(
  getCurrentVif,
  getCurrentSeriesIndex,
  (vif, seriesIndex) => _.get(vif, ['series', seriesIndex, 'primary'], VIF_CONSTANTS.DEFAULT_MAP_LAYER_PRIMARY_VALUE)
);

export const isValidRegionMapVif = createSelector(
  getDimension,
  getMeasure,
  getDatasetUid,
  getDomain,
  getSeriesZero,
  (dimension, measure, datasetUid, domain, series) => {
    var hasComputedColumnName = _.isString(_.get(series, 'computedColumnName'));
    var hasShapeFileUid = _.isString(_.get(series, 'shapefile.uid'));
    var hasShapeFilePrimaryKey = _.isString(_.get(series, 'shapefile.primaryKey'));
    var hasDimension = _.isString(_.get(dimension, 'columnName'));

    var hasMeasureAggregation = _.isString(_.get(measure, 'aggregationFunction'));

    var hasDatasetUid = _.isString(datasetUid);
    var hasDomain = _.isString(domain);

    return hasComputedColumnName &&
      hasShapeFileUid &&
      hasShapeFilePrimaryKey &&
      hasDimension &&
      hasDatasetUid &&
      hasDomain &&
      hasMeasureAggregation;
  }
);

export const isChartOfType = (types) => createSelector(
  getVisualizationType,
  (type) => _.includes(types, type)
);

export const isBarChart = createSelector(
  getCurrentVif,
  vifSelectors.isBarVisualization
);

export const isValidBarChartVif = createSelector(
  getDimension,
  getDatasetUid,
  getDomain,
  (dimension, datasetUid, domain) => {
    var hasDimension = _.isString(_.get(dimension, 'columnName'));
    var hasDatasetUid = _.isString(datasetUid);
    var hasDomain = _.isString(domain);

    return hasDimension && hasDatasetUid && hasDomain;
  }
);

export const isColumnChart = createSelector(
  getVisualizationType,
  (type) => type === 'columnChart'
);

export const isValidColumnChartVif = createSelector(
  getDimension,
  getDatasetUid,
  getDomain,
  (dimension, datasetUid, domain) => {
    var hasDimension = _.isString(_.get(dimension, 'columnName'));
    var hasDatasetUid = _.isString(datasetUid);
    var hasDomain = _.isString(domain);

    return hasDimension && hasDatasetUid && hasDomain;
  }
);

export const isComboChart = createSelector(
  getVisualizationType,
  (visualizationType) => {
    const parts = (visualizationType || '').split('.');
    const type = (parts.length > 0) ? parts[0] : visualizationType;

    return type === 'comboChart';
  }
);

export const isValidComboChartVif = createSelector(
  getDimension,
  getDatasetUid,
  getDomain,
  (dimension, datasetUid, domain) => {
    var hasDimension = _.isString(_.get(dimension, 'columnName'));
    var hasDatasetUid = _.isString(datasetUid);
    var hasDomain = _.isString(domain);

    return hasDimension && hasDatasetUid && hasDomain;
  }
);

export const isScatterChart = createSelector(
  getCurrentVif,
  vifSelectors.isScatterVisualization
);

export const isValidScatterChartVif = createSelector(
  getDatasetUid,
  getDomain,
  (datasetUid, domain) => {
    const hasDatasetUid = _.isString(datasetUid);
    const hasDomain = _.isString(domain);

    return hasDatasetUid && hasDomain;
  }
);

export const isCalendar = createSelector(
  getVisualizationType,
  (type) => type === 'calendar'
);

export const isValidCalendarVif = createSelector(
  getDatasetUid,
  getDomain,
  (datasetUid, domain) => {
    const hasDatasetUid = _.isString(datasetUid);
    const hasDomain = _.isString(domain);

    return hasDatasetUid && hasDomain;
  }
);

// May need to update this to include AGgrid table
export const isTable = createSelector(
  getVisualizationType,
  (type) => type === SERIES_TYPE_TABLE || type === SERIES_TYPE_AG_GRID_TABLE
);

export const isValidTableVif = createSelector(
  getTableColumns,
  getDatasetUid,
  getDomain,
  (tableColumns, datasetUid, domain) => {
    const hasDatasetUid = _.isString(datasetUid);
    const hasDomain = _.isString(domain);
    const hasColumns = !_.isEmpty(tableColumns);

    return hasDatasetUid && hasDomain && hasColumns;
  }
);

export const isDataTable = createSelector(
  getVisualizationType,
  (type) => type === SERIES_TYPE_DATA_TABLE
);

export const isValidDataTableVif = createSelector(
  getDatasetUid,
  getDomain,
  (datasetUid, domain) => {
    const hasDatasetUid = _.isString(datasetUid);
    const hasDomain = _.isString(domain);

    return hasDatasetUid && hasDomain;
  }
);

export const isAgTable = createSelector(
  getVisualizationType,
  (type) => type === SERIES_TYPE_AG_GRID_TABLE
);

export const isValidAgTableVif = createSelector(
  getTableColumns,
  getDatasetUid,
  getDomain,
  (tableColumns, datasetUid, domain) => {
    const hasDatasetUid = _.isString(datasetUid);
    const hasDomain = _.isString(domain);
    const hasColumns = !_.isEmpty(tableColumns);

    return hasDatasetUid && hasDomain && hasColumns;
  }
);

export const hasSelectedTableVisualizationColumns = createSelector(
  getDimension,
  isTable,
  (dimension, isTableType) => {
    const selectedColumnsLength = _.get(dimension, 'columns.length', 0);
    return selectedColumnsLength > 0 && isTableType;
  }
);

export const isHistogram = createSelector(
  getVisualizationType,
  (type) => type === 'histogram'
);

export const isValidHistogramVif = createSelector(
  getDimension,
  getMeasure,
  getDatasetUid,
  getDomain,
  (dimension, measure, datasetUid, domain) => {
    var hasDimension = _.isString(_.get(dimension, 'columnName'));
    var hasDatasetUid = _.isString(datasetUid);
    var hasDomain = _.isString(domain);

    return hasDimension && hasDatasetUid && hasDomain;
  }
);

export const isFeatureMap = createSelector(
  getVisualizationType,
  (type) => type === 'featureMap'
);

export const isNewGLMap = createSelector(
  getVisualizationType,
  (type) => {
    return type === 'map';
  }
);

export const isValidNewGLMapVif = createSelector(
  getSeriesZero,
  (series) => {
    const hasDimension = _.isString(_.get(series, 'dataSource.dimension.columnName'));
    const hasDatasetUid = _.isString(_.get(series, 'dataSource.datasetUid'));
    const hasComputedColumnName = _.isString(_.get(series, 'computedColumnName'));
    const hasShapeFileUid = _.isString(_.get(series, 'shapefile.uid'));
    const hasMeasureAggregation = _.isString(_.get(series, 'dataSource.measure.aggregationFunction'));

    return (hasDimension && hasDatasetUid) ||
      (hasDimension &&
        hasDatasetUid &&
        hasComputedColumnName &&
        hasShapeFileUid &&
        hasMeasureAggregation);
  }
);

export const isValidFeatureMapVif = createSelector(
  getDimension,
  getDatasetUid,
  getDomain,
  (dimension, datasetUid, domain) => {
    var hasDimension = _.isString(_.get(dimension, 'columnName'));
    var hasDatasetUid = _.isString(datasetUid);
    var hasDomain = _.isString(domain);

    return hasDimension && hasDatasetUid && hasDomain;
  }
);

export const isTimelineChart = createSelector(
  getVisualizationType,
  (type) => type === 'timelineChart'
);

export const isValidTimelineChartVif = createSelector(
  getDimension,
  getMeasure,
  getDatasetUid,
  getDomain,
  (dimension, measure, datasetUid, domain) => {
    var hasDimension = _.isString(_.get(dimension, 'columnName'));
    var hasDatasetUid = _.isString(datasetUid);
    var hasDomain = _.isString(domain);

    return hasDimension && hasDatasetUid && hasDomain;
  }
);

export const isPieChart = createSelector(
  getVisualizationType,
  (type) => type === 'pieChart'
);

export const isValidPieChartVif = createSelector(
  getDimension,
  getMeasure,
  getDatasetUid,
  getDomain,
  (dimension, measure, datasetUid, domain) => {
    var hasDimension = _.isString(_.get(dimension, 'columnName'));
    var hasDatasetUid = _.isString(datasetUid);
    var hasDomain = _.isString(domain);

    return hasDimension && hasDatasetUid && hasDomain;
  }
);

export const isValidVif = createSelector(
  isBarChart,
  isValidBarChartVif,
  isNewGLMap,
  isValidNewGLMapVif,
  isFeatureMap,
  isValidFeatureMapVif,
  isRegionMap,
  isValidRegionMapVif,
  isColumnChart,
  isValidColumnChartVif,
  isComboChart,
  isValidComboChartVif,
  isCalendar,
  isValidCalendarVif,
  isScatterChart,
  isValidScatterChartVif,
  isPieChart,
  isValidPieChartVif,
  isTimelineChart,
  isValidTimelineChartVif,
  isHistogram,
  isValidHistogramVif,
  isTable,
  isValidTableVif,
  isDataTable,
  isValidDataTableVif,
  isAgTable,
  isValidAgTableVif,
  (
    isBarChart,
    validBarChart,
    isNewGLMap,
    validNewMap,
    isFeatureMap,
    validFeatureMap,
    isRegionMap,
    validRegionMap,
    isColumnChart,
    validColumnChart,
    isComboChart,
    validComboChart,
    isCalendar,
    validCalendar,
    isScatterChart,
    validScatterChart,
    isPieChart,
    validPieChart,
    isTimelineChart,
    validTimelineChart,
    isHistogram,
    validHistogramVif,
    isTable,
    validTableVif,
    isDataTable,
    isValidDataTableVif,
    isAgTable,
    isValidAgTableVif,
  ) => {
    return (
      isBarChart && validBarChart ||
      isNewGLMap && validNewMap ||
      isFeatureMap && validFeatureMap ||
      isRegionMap && validRegionMap ||
      isColumnChart && validColumnChart ||
      isComboChart && validComboChart ||
      isCalendar && validCalendar ||
      isScatterChart && validScatterChart ||
      isPieChart && validPieChart ||
      isTimelineChart && validTimelineChart ||
      isHistogram && validHistogramVif ||
      isTable && validTableVif ||
      isDataTable && isValidDataTableVif ||
      isAgTable && isValidAgTableVif
    );
  }
);

export const isMap = createSelector(
  isNewGLMap,
  isRegionMap,
  isFeatureMap,
  (isNewGLMap, isRegionMap, isFeatureMap) => isNewGLMap || isRegionMap || isFeatureMap
);

export const isRenderableMap = createSelector(
  isNewGLMap,
  isValidNewGLMapVif,
  isRegionMap,
  isValidRegionMapVif,
  isFeatureMap,
  isValidFeatureMapVif,
  (isNewGLMap, isValidNewGLMapVif, isRegionMap, isValidRegionMapVif, isFeatureMap, isValidFeatureMapVif) => {
    return (isNewGLMap && isValidNewGLMapVif) ||
      (isFeatureMap && isValidFeatureMapVif) ||
      (isRegionMap && isValidRegionMapVif);
  }
);

export const isInsertableVisualization = createSelector(
  getShowCenteringAndZoomingSaveMessage,
  isValidVif,
  (showCenteringAndZoomingSaveMessage, isValidVif) => {
    return !showCenteringAndZoomingSaveMessage && isValidVif;
  }
);

export const hasMadeChangesToVifs = createSelector(
  getVifs,
  getCheckpointVifs,
  (modifiedVifs, checkpointVifs) => {
    const clonedModifiedVifs = _.cloneDeep(modifiedVifs);
    const clonedCheckpointVifs = _.cloneDeep(checkpointVifs);

    // Data tables aren't a configurable visualization
    // and have their own thing going on, so
    // don't compare them.
    _.unset(clonedModifiedVifs, 'dataTable');
    _.unset(clonedCheckpointVifs, 'dataTable');

    return !_.isEqual(clonedModifiedVifs, clonedCheckpointVifs);
  }
);
